<template>
<div>
     <Header role = "admin"/>
  <div class="d-flex" id="wrapper">
    <Sidebar role = "admin"/>
  <div id="page-content-wrapper" style="background-color: #f3f2ef;">
     <div class="row">
       <div class="col-lg-11 col-12">
         <div class="text w-100">
          <router-view/>
         </div>
       </div>
       <!-- <div class="col-lg-4 d-lg-block d-none">
           <MessageList/>
       </div> -->
     </div>


  </div>
  </div>
  <div class="d-block d-lg-none" v-if="!['Doctor-New-Message', 'Doctor-Message-Chat', 'Doctor-Comment'].includes($route.name)">
       <Footer role = "admin"></Footer>
  </div>
  </div>
</template>

<script>
import Sidebar from '../layouts/Sidebar.vue'
import Header from '../layouts/Header.vue'
import Footer from '../layouts/CommonFooter.vue'
// import MessageList from '../../views/common/messagelist.vue'
import '../../css/app.css'
export default {

  name: 'app',
  components: {
    Sidebar,
    Header,
    Footer,
    // MessageList,
  },
  data() {
    return {
    adminID:''
    }},
    created: async function () {
      await this.getAdmin();
    },
    methods: {
  async getAdmin() {
  this.adminID = localStorage.getItem('AdminsignIn')
if(!this.adminID){
    window.location.href = "/";
  }
}  
 } 
}
</script>
<style>
  .text.w-100{
    margin-bottom:60px;
  }
</style>
